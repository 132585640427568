<template>
  <div class="py-4">
    <div class="container" v-if="inited">
      <div v-if="hasToken">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mb-3" v-for="(item, index) in sortedHistoryListData" :key="index" >
            <HistoryItem :item-data="item" @delete="deleteOrder" />
          </div>
        </div>
      </div>
  
      <div v-else>
        <ShopNotLogin></ShopNotLogin>
      </div>
    </div>
  </div>
</template>


<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import { mapState, mapActions, mapGetters } from 'vuex';
import ShopNotLogin from "../../components/loopii/ShopNotLogin.vue";
import HistoryItem from "@/views/share/HistoryItem.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopOrdersView',
  data() {
    return {
      inited: false,
    };
  },
  props: {
    baSerial: {
      type: Number,
      required: true,
    }
  },
  components: {
    ShopNotLogin,
    HistoryItem,
  },
  computed: {
		... mapGetters(['hasToken']),
    ... mapState('event', ['historyListData']),
    sortedHistoryListData() {
      // return this.historyListData
      //     ? [...this.historyListData]
      //         // .filter(item => item.status > 1)  // 過濾掉 status <= 1 的資料
      //         .sort((a, b) => {
      //           return new Date(b.createdDate) - new Date(a.createdDate);
      //         })
      //     : null;
      if (!this.historyListData) return null;

      const listInThisShop = this.historyListData.filter((evt) => {
        return evt.businessAccountSerial === this.baSerial;
      });
      // 先過濾出 status 为 0, 1, 4, 5 的數據並按時間排序
      const prioritized = [... listInThisShop]
          .filter(item => [0, 1, 4, 5].includes(item.status))
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // 剩下的其他數據按時間排序
      const others = [...listInThisShop]
          .filter(item => ![0, 1, 4, 5].includes(item.status))
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // 把 prioritized 列表放在前面，others 列表放在后面
      return [...prioritized, ...others];
    }
	},
  watch: {
    
  },
  mounted() {
    try {
      if (this.hasToken) {
        this.handleAsyncTask(async () => {
          await this.refreshHistoryList();
          this.inited = true;
        });
      } else {
        this.inited = true;
      }
    } catch (error) {
      console.error(error);
      this.showMsgModal(error);
    }
	},
  methods: {
    ... mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    ... mapActions('event', ['refreshHistoryList', 'clearHistoryListData', 'removeFromHistoryListData']),
    deleteOrder() {

    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";

</style>