<template>
  <div class="not-login">
    <p>
      請先登入後即可觀看完整功能與服務<br/>
      感謝您的支持！
    </p>

    <b-button variant="primary" class="w-100" @click="toLogin">
      登入
    </b-button>
  </div>
</template>


<script>
import { mapActions } from 'vuex';
export default {
  name: 'ShopNotLogin',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
  },
  computed: {
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    ...mapActions('loopii', ['redirectToLogin', 'setRedirect', 'getRedirect']),
    toLogin() {
      this.redirectToLogin(this.$route);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.not-login {
  p {
    color: $typography-primary-default;
  }
}
</style>